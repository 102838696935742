import React from "react";
import "./rotating-circular-text.css";

function RotatingCircularText() {
  return (
      <div className="flex justify-center items-center absolute z-10">
        <img
          src="https://user-images.githubusercontent.com/48355572/268242852-96da075d-9ada-44a3-880c-44bfeed9233b.gif"
          width="50"
          height="50"
          className="absolute animate-circley-reverse filter drop-shadow-md"
        />
        <svg
          id="rotatingText"
          viewBox="-10 -10 220 220"
          width="190"
          height="190"
          className="animate-circley"
        >
          <defs>
            <path
              id="circley"
              d="M 100, 100
              m -49, 0
              a 49, 49 0 1, 0 98, 0
              a 49, 49 0 1, 0 -98, 0"
            ></path>
          </defs>
          <text width="200">
            <textPath
              alignmentBaseline="top"
              xlinkHref="#circley"
              className="font-bold tracking-wide uppercase text-xs fill-current text-green-500 dark:text-teal-300 text-shadow-md animate-pulse"
            >
              Won over 60 International Hackathons!
            </textPath>
          </text>
        </svg>
      </div>
  );
}

export default RotatingCircularText;
